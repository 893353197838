<template>
	<div class="container" v-loading="Editloading">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="220px">
			<el-card class="box-card">
				<div slot="header" class="clearfix">
					<span>角色信息</span>
				</div>
				<div class="content">
					<el-form-item label="角色名称：" :inline-message='true' prop="Name" label-width="100px">
						<el-input v-model="ruleForm.Name" style="width: 300px;" placeholder="最多输入20个字" maxlength="20"></el-input>
					</el-form-item>
				</div>
			</el-card>
			
			<el-card class="box-card" style="margin:10px 0">
				<div slot="header" class="clearfix">
					<span>PC后台菜单权限</span>
				</div>
				<div class="content">
					<!--  -->
					<el-tree :data="menuList" :props="{children: 'ChildMenuDtoList', label: 'Text'}" show-checkbox node-key="Id"
					highlight-current :default-checked-keys="jurisdictionList" ref="tree" :check-strictly="true" @check="checkTids">
					</el-tree>
				</div>
			</el-card>

			<el-card class="box-card" style="margin-bottom:90px">
				<div slot="header" class="clearfix">
					<span>其他权限</span>
				</div>
				<div class="content">
					<!-- 使用商品 -->
					<el-form-item :label="IsXiTai?'数据可视范围：':'企店员工助手数据可视范围：'" required>
						<div class="radio">
							<el-radio v-model="ruleForm.permission" label="1" @change='handleChangeRadio'>仅看自己</el-radio>
						</div>
						<div class="radio" v-if="!IsXiTai">
							<el-radio v-model="ruleForm.permission" label="2" @change='handleChangeRadio'>仅员工归属门店</el-radio>
						</div>
						<div class="radio" v-if="!IsXiTai">
							<el-radio v-model="ruleForm.permission" label="3" @change='handleChangeRadio'>指定门店</el-radio>
							<span v-if="ruleForm.permission=='3'" style="color:#409EFF;cursor: pointer;" @click="showShopPop">选择门店</span>
							<div>
								<el-tag :key="tag.Id" v-for="(tag, index) in ruleForm.permissionList" closable style="margin-right:5px;" type='info'
								 :disable-transitions="false" @close="handleShopClose(tag, index)">
									{{tag.ShopName}}
								</el-tag>
							</div>
						</div>
						<div class="radio" v-if="!IsXiTai">
							<el-radio v-model="ruleForm.permission" label="5" @change='handleChangeRadio'>指定门店标签</el-radio>
							<span v-if="ruleForm.permission =='5'" style="color:#409EFF;cursor: pointer;" @click="showShopTag">选择标签</span>
							<div>
								<el-tag :key="tag.Id" v-for="(tag, index) in ruleForm.permissionTagList" closable style="margin-right:5px;"
								 type='info' :disable-transitions="false" @close="handleTagClose(tag, index)">
									{{tag.ShopTagName}}
								</el-tag>
							</div>
						</div>
						<div class="radio">
							<el-radio v-model="ruleForm.permission" label="4" @change='handleChangeRadio'>{{IsXiTai?'所有数据':'所有门店'}}</el-radio>
							<!-- <span v-if="ruleForm.permission=='4'" style="color:#409EFF;cursor: pointer;" @click="selectProDialog=true">选择商品</span> -->
						</div>
					</el-form-item>

					<el-form-item label="商城数据助手登录权限：" v-if="!IsXiTai">
						<el-checkbox :label="true" v-model="ruleForm.IsCanLoginQianDianYunYing">允许登录</el-checkbox>
					</el-form-item>

					<el-form-item label="用户隐私查看权限：" v-if="!IsXiTai">
						<el-checkbox :label="true" v-model="ruleForm.IsOpenLookUserPrivacyAuthority">开启</el-checkbox>
						<div class="grayfont">开启查看权限后，订单、售后单等模块的用户手机号、身份证号等会完整展示</div>
					</el-form-item>
				</div>
			</el-card>
		</el-form>


		<div class="box-card footer">
			<el-button style="width:240px;" @click="cancelSupiler()">取消</el-button>
			<el-button style="width:240px;" type="primary" @click="saveSupiler('ruleForm')" :loading="loading">保存</el-button>
		</div>

		<!-- 选择门店 -->
		<el-dialog title="选择门店" :visible.sync="visible" v-if='visible' width="70%" class="dialog">
			<div class="search">
				<div>关键字<el-input v-model="keywords" size="mini" placeholder="门店名称、门店编号" maxlength="200" style="width:160px;margin-left:10px;margin-right:30px"></el-input>
				</div>
				<div>门店标签
					<el-select v-model="tag" placeholder="请选择" filterable clearable size="mini" style="width: 160px;margin-left: 10px;">
						<el-option :key="-1" label="全部" :value="null">
						</el-option>
						<el-option v-for="(item,index) in tagList" :key="index" :label="item.TagName" :value="item.Id">
						</el-option>
					</el-select>
				</div>
				<el-button style="width:80px;margin-left: 20px;" type="primary" size="mini" @click="handleSearch">查询</el-button>
			</div>

			<div class="table-box">
				<el-table :data="shopList" v-loading="loading" ref="compSelectProTable" style="width: 100%" :row-key="getRowKeys"
				 @selection-change="handleSelectionChange">
					<el-table-column type="selection" width="55" label="当前页全选" :reserve-selection="true"></el-table-column>
					<el-table-column prop="ShopNo" label="门店编码"></el-table-column>
					<el-table-column prop="ShopName" label="门店名称"></el-table-column>
					<el-table-column prop="ShopTagName" label="门店标签"></el-table-column>
				</el-table>
				<el-checkbox style="margin-top:25px;margin-left:13px;float:left;" :value="checkedAll" @change="selectCurrentAll">当前页全选</el-checkbox>
				<el-pagination style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handlePageChange"
				 :current-page="pageIndex" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="total">
				</el-pagination>
			</div>
			<div slot="footer" class="dialog-footer">
				已选中
				<span style="color:#F56C6C">{{selectedList.length}}</span>
				家
				<el-button class="button" @click="saveSelectProList" style="margin-left:10px;" type="primary">确定</el-button>
			</div>
		</el-dialog>

		<!-- 选择标签 -->
		<el-dialog title="选择标签" :visible.sync="tagVisible" v-if='tagVisible' width="50%" class="dialog">
			<div class="search">
				<div>关键字<el-input v-model="tagKeyWords" size="mini" placeholder="标签名称" maxlength="200" style="width:160px;margin-left:10px;margin-right:30px"></el-input>
				</div>
				<el-button style="width:80px;margin-left: 20px;" type="primary" size="mini" @click="handleSignSearch">查询</el-button>
			</div>

			<div class="table-box">
				<el-table :data="signList" v-loading="loading" ref="compSelectTagTable" style="width: 100%" :row-key="getTagRowKeys"
				 @selection-change="handleSelectionChangeTag">
					<el-table-column type="selection" width="55" label="当前页全选" :reserve-selection="true">
					</el-table-column>
					<el-table-column prop="ShopTagName" label="门店标签"></el-table-column>
				</el-table>
				<el-checkbox style="margin-top:25px;margin-left:13px;float:left;" :value="checkedTagAll" @change="selectTagCurrentAll">当前页全选</el-checkbox>
				<el-pagination style="margin-top:20px;float:right;" @size-change="handleTagSizeChange" @current-change="handleTagPageChange"
				 :current-page="tagPage.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="tagPage.size" layout="total, sizes, prev, pager, next, jumper"
				 :total="tagPage.total">
				</el-pagination>
			</div>
			<div slot="footer" class="dialog-footer">
				已选中
				<span style="color:#F56C6C">{{selectedTagList.length}}</span>
				家
				<el-button class="button" @click="saveSelectTagList" style="margin-left:10px;" type="primary">确定</el-button>
			</div>
		</el-dialog>
		<!-- <div class="table-box">
				<el-table :data="tagList" v-loading="loading" ref="compSelectProTable" style="width: 100%" :row-key="getRowKeys"
				 @selection-change="handleSelectionChangeTag">
					<el-table-column type="selection" width="55" label="当前页全选" :reserve-selection="true">
					</el-table-column>
					<el-table-column prop="ShopNo" label="门店编码"></el-table-column>
					<el-table-column prop="ShopName" label="门店名称"></el-table-column>
					<el-table-column prop="ShopTagName" label="门店标签"></el-table-column>
				</el-table>
				<el-checkbox style="margin-top:25px;margin-left:13px;float:left;" :value="checkedTagAll" @change="selectTagCurrentAll">当前页全选</el-checkbox>
				<el-pagination style="margin-top:20px;float:right;" @size-change="handleTagSizeChange" @current-change="handlePageChange"
				 :current-page="tagPage.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="tagPage.size" layout="total, sizes, prev, pager, next, jumper"
				 :total="tagPage.total">
				</el-pagination>
			</div> -->
	</div>
</template>

<script>
	import config from '@/config/index'
	import {
		shoptaglist,
		employeeshoplist,
		employeeroleedit,
		employeeroleinfo
	} from '@/api/api.js';
	import {
		Mallalljurisdict
	} from '@/api/TurnTomySelf.js'
	import apiList from '@/api/other.js'

	import {
		mapGetters
	} from 'vuex';
	export default {
		data() {
			return {
				Editloading: false,
				menuList: [],
				jurisdictionList: [],
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				loading: false,
				id: '',
				ruleForm: {
					Name: '',
					permission: '0',
					permissionList: [],
					permissionTagList: [],
					IsCanLoginQianDianYunYing:false,
					IsOpenLookUserPrivacyAuthority:false
				},
				currentCount: '',
				rules: {
					Name: [{
						required: true,
						message: '请输入角色名称',
						trigger: 'blur'
					}],
				},

				//选择门店
				visible: false,
				keywords: '',
				shopList: [],
				tagList: [],
				selectedList: [],
				tag: '',
				pageIndex: 1,
				pageSize: 10,
				total: 0,
				selectKey: [],
				selectedKey: [],
				// 选择表单
				signList: [],
				selectedTagList: [],
				tagKeyWords: '',
				tagVisible: false,
				tagPage: {
					size: 10,
					total: 0,
					current: 1
				}
			}
		},
		computed: {
			checkedAll() {
				var val = this.selectedList;
				let ableSelectLength = 0 //获取当前页可选的全部页数
				var remarkLen = 0;
				var ids = val.map(item => {
					return item.Id
				})
				this.shopList.forEach(item => {
					if (ids.indexOf(item.Id) > -1) {
						ableSelectLength++;
					}
				});
				return (ableSelectLength > 0 && this.shopList.length == ableSelectLength) ? true : false;
			},
			checkedTagAll() {
				var val = this.selectedTagList;
				let ableSelectLength = 0 //获取当前页可选的全部页数
				var remarkLen = 0;
				var ids = val.map(item => {
					return item.ShopTagId
				})
				this.signList.forEach(item => {
					if (ids.indexOf(item.ShopTagId) > -1) {
						ableSelectLength++;
					}
				});
				return (ableSelectLength > 0 && this.signList.length == ableSelectLength) ? true : false;
			},
			...mapGetters([
				'IsXiTai'
			])
		},
		created() {
			this.id = this.$route.query.id || 0;
			this.getMenlist()
		},
		beforeMount() {
			if (this.id) {
				this.initInfo();
			}

			this.getTagList();
		},
		methods: {
			checkTids(data, checked, indeterminate) {

				this.checkedList = checked.checkedKeys;

				if (data.IsButton) {
					this.checkParents(this.menuList, data);
					if (checked.checkedKeys.indexOf(data.Id) > -1) {
						this.checkedList.push(data.Id)
					}
				} else {
					if (checked.checkedKeys.indexOf(data.Id) > -1) {
						this.checkAllData(this.menuList, data);
						// this.checkedList.push(data.Id)
					} else {
						this.notCheckAllData(this.menuList, data);
					}
				}

				this.$refs.tree.setCheckedKeys(this.checkedList, true);

			},
			checkParents(list, record) {
				list.map(item => {
					//父标签都选中
					if (record && record.parentList && record.parentList.indexOf(item.Id) > -1) {
						this.checkedList.push(item.Id);
					}

					if (item.ChildMenuDtoList && item.ChildMenuDtoList.length) {
						this.checkParents(item.ChildMenuDtoList, record)
					}

				})
			},
			checkAllData(list, record) {
				list.map(item => {
					//父标签都选中
					if (record && record.parentList && record.parentList.indexOf(item.Id) > -1) {
						this.checkedList.push(item.Id);
					}
					//字标签 都选中
					if (item.parentList && item.parentList.indexOf(record.Id) > -1) {
						this.checkedList.push(item.Id);
					}

					if (item.ChildMenuDtoList && item.ChildMenuDtoList.length) {
						this.checkAllData(item.ChildMenuDtoList, record)
					}
				})
			},
			notCheckAllData(list, record) {
				list.map(item => {
					//过滤掉当前标签下所有子节点
					if (item.parentList && item.parentList.indexOf(record.Id) > -1) {
						this.checkedList = this.checkedList.filter(i => {
							return i != item.Id
						})
					}
					if (item.ChildMenuDtoList && item.ChildMenuDtoList.length) {
						this.notCheckAllData(item.ChildMenuDtoList, record)
					}
				})
			},
			//获取所有权限
			async getMenlist() {
				try {
					let data = {
						MallEmployeeRoleId: this.id
					}
					let result = await Mallalljurisdict(data)
					if (result.Result) {
						var list = result.Result.filter(item => {
							return item.Text != '测试菜单'
						})
						// this.menuList = this.filterChecked(list);
						// console.log(this.menuList)
						this.menuList = this.norec(this.filterChecked(list))
						// console.log(this.menuList)
						this.jurisdictionList = this.unique(this.jurisdictionList);
						setTimeout(() => {

							if (this.$refs.tree) {
								this.$refs.tree.setCheckedKeys(this.jurisdictionList);
							}
						}, 260)
					}
				} catch (err) {

				} finally {

				}
			},
			norec(list){
				let a =  list.filter((v)=>{
					if(v.ChildMenuDtoList && v.ChildMenuDtoList.length){
						v.ChildMenuDtoList = this.norec(v.ChildMenuDtoList)
					}
					return v.Sref != 'myrecommended'
				})
				return a
			},
			filterChecked(list, record) {
				return list.map(item => {
					if (record) {
						var obj = JSON.parse(JSON.stringify(record))
						// if (!item.parentList){
						// 	item.parentList = [];
						// }
						item.parentList = obj.parentList || [];
						item.parentList.push(obj.Id)
						item.ParentId = obj.Id;
					}
					if (item.IsCheck) {
						this.jurisdictionList.push(item.Id)
					}
					if (item.ChildMenuDtoList && item.ChildMenuDtoList.length) {
						this.filterChecked(item.ChildMenuDtoList, item)
					}
					return item
					// return item.Id!=621
				})
			},
			// 编辑时初始数据
			async initInfo() {
				this.Editloading = true
				try {
					const res = await employeeroleinfo({
						Id: this.id
					})
					if (res.IsSuccess) {
						for(let key in res.Result){
							this.ruleForm[key] = res.Result[key]
						}
						// console.log(this.ruleForm)
						if (res.Result.IsBindShopTag) {
							this.ruleForm.permission = '5'
							this.ruleForm.permissionTagList = res.Result.ShopTagList.map(t => {
								return {
									ShopTagId: t.Id,
									ShopTagName: t.TagName,
								}
							})
						} else {
							this.ruleForm.permission = res.Result.ViewRange + 1 + '';
							this.ruleForm.permissionList = res.Result.ShopList
						}
						this.selectedList = res.Result.ShopList;
					}
					this.slectKeys = res.Result.EmployeeRolePcMenuList
				} catch (err) {

				} finally {
					this.Editloading = false
				}



			},

			async getTagList() {
				const res = await shoptaglist();

				if (res.IsSuccess) {
					this.tagList = res.Result || [];
				}

			},
			handleSignSearch() {
				this.tagPage.current = 1
				this.getSignList()
			},
			async getSignList() {

				this.loading = true;

				const res = await apiList.shopTagList({
					ShopTagName: this.tagKeyWords,
					Skip: (this.tagPage.current - 1) * this.tagPage.size,
					Take: this.tagPage.size
				})

				this.loading = false;

				if (res.IsSuccess) {
					this.signList = res.Result.Results;
					this.tagPage.total = res.Result.Total;

					this.isReady = true;
				}
			},
			async getShopList() {

				this.loading = true;

				const res = await employeeshoplist({
					Id: this.id,
					KeyWords: this.keywords,
					ShopTagId: this.tag,
					Skip: (this.pageIndex - 1) * this.pageSize,
					Take: this.pageSize
				})

				this.loading = false;

				if (res.IsSuccess) {
					this.shopList = res.Result.Results;
					this.total = res.Result.Total;

					this.isReady = true;
				}
			},
			showShopPop() {
				this.visible = true;
				this.selectedList = this.ruleForm.permissionList
				this.getShopList();
				this.$nextTick(() => {
					if (this.selectedList.length) {
						this.selectedList.map(item => {
							this.$refs['compSelectProTable'].toggleRowSelection(item, true);
						})
					}

				})


			},
			showShopTag() {
				this.tagVisible = true;
				this.selectedTagList = this.ruleForm.permissionTagList
				this.getSignList()
				this.$nextTick(() => {
					if (this.selectedTagList.length) {
						this.selectedTagList.map(item => {
							this.$refs['compSelectTagTable'].toggleRowSelection(item, true);
						})
					}
				})
			},
			handleChangeRadio(e) {
				console.log(e)
				this.ruleForm.permissionList = []
				this.ruleForm.permissionTagList = []
			},
			handleClose() {
				// this.$refs['compSelectProTable'].clearSelection();
				// this.visible = false;
			},
			handleSearch() {
				this.pageIndex = 1;
				this.getShopList();
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.getShopList();
			},
			handlePageChange(val) {
				this.pageIndex = val;
				this.getShopList();
			},
			handleTagSizeChange(val) {
				this.tagPage.size = val;
				this.getSignList();
			},
			handleTagPageChange(val) {
				this.tagPage.current = val;
				this.getSignList();
			},
			getRowKeys(row) {
				return row.Id
			},
			getTagRowKeys(row) {
				return row.ShopTagId
			},
			handleSelectionChange(val) {
				this.selectedList = val;
			},
			handleSelectionChangeTag(val) {
				this.selectedTagList = val
			},
			selectCurrentAll(e) {
				this.$refs['compSelectProTable'].toggleAllSelection()
			},
			selectTagCurrentAll(e) {
				this.$refs['compSelectTagTable'].toggleAllSelection()
			},
			saveSelectProList() {
				if (this.selectedList.length == 0) {
					this.$message({
						showClose: true,
						type: 'info',
						message: '请选择门店'
					})
				} else {
					this.ruleForm.permissionList = this.selectedList;
					this.visible = false;
				}
			},
			saveSelectTagList() {
				if (this.selectedTagList.length == 0) {
					this.$message({
						showClose: true,
						type: 'info',
						message: '请选择表单'
					})
				} else {
					this.ruleForm.permissionTagList = this.selectedTagList;
					this.tagVisible = false;
				}
			},
			//取消保存
			cancelSupiler() {
				this.$router.push({
					path: '/setting/roleManage/index'
				})
			},
			// 保存
			saveSupiler(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.ruleForm.permission == 0) {
							this.$message({
								showClose: true,
								type: 'error',
								message: '请配置数据统计可视范围'
							})
						} else {
							this.sureSave();

						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			// 数组去重
			unique(arr) {
				const res = new Map();
				return arr.filter((arr) => !res.has(arr) && res.set(arr, 1))
			},
			async sureSave() {
				// this.loading= true
				let arrs = this.$refs.tree.getCheckedNodes();
				// console.log(this.ruleForm.permissionTagList)
				this.selectKey = arrs.map(ite => {
					return ite.Id
				})
				const res = await employeeroleedit({
					Id: this.id,
					Name: this.ruleForm.Name,
					ViewRange: this.ruleForm.permission == 5 ? 2 : +this.ruleForm.permission - 1,
					ShopList: this.ruleForm.permissionList.map(item => {
						return item.Id
					}),
					IsBindShopTag: this.ruleForm.permission == 5,
					ShopTagList: this.ruleForm.permissionTagList.map(item => {
						return item.ShopTagId
					}),
					MenuList: this.selectKey,
					IsCanLoginQianDianYunYing:this.ruleForm.IsCanLoginQianDianYunYing,
					IsOpenLookUserPrivacyAuthority:this.ruleForm.IsOpenLookUserPrivacyAuthority,
				})


				if (res.IsSuccess) {
					this.$router.back();
				}
				this.loading = false
			},
			handleShopClose(tag, index) {
				this.ruleForm.permissionList = this.ruleForm.permissionList.filter(item => {
					return item.Id != tag.Id
				})
			},
			handleTagClose(tag, index) {
				this.ruleForm.permissionTagList = this.ruleForm.permissionTagList.filter(item => {
					return item.ShopTagId != tag.ShopTagId
				})
			},
		}
	}
</script>

<style lang="less" scoped>
.grayfont{
font-size: 12px;
color: #999999;
line-height: 1.5;
}
	.container {
		// padding: 10px;
		width: 100%;
		overflow: hidden;

		::v-deep .el-textarea {
			width: 500px;
		}
	}

	.upload-avatar {
		width: 145px;
		height: 145px;
		object-fit: contain;
	}

	.footer {
		margin-left: -10px;
		background-color: #fff;
		z-index: 999;
		width: 100%;
		margin-right: 10px;
		height: 80px;
		text-align: center;
		line-height: 80px;
		position: fixed;
		bottom: 0px;
	}

	.table-box {
		overflow: hidden;
		margin-top: 20px;
	}

	.search {
		display: flex;
	}
</style>
